import type {
  GetServerSidePropsContext,
  InferGetServerSidePropsType
} from "next"
import { signIn } from "next-auth/react"
import { getServerSession } from "next-auth/next"
import { authOptions } from "../server/nextauth"
import { getUser } from "utils/auth"
import { Router } from "next/router"
import style from "../style/login.module.css"

export default function Login({}: InferGetServerSidePropsType<
  typeof getServerSideProps
>) {
  return (
    <div>
      <button
        className={style["login-btn"]}
        onClick={() =>
          signIn(
            "azure-ad",
            {
              callbackUrl: `${process.env.NEXTAUTH_URL}/orders`
            },
            { prompt: "login" }
          )
        }>
        המשיכו להתחברות לחשבון שלכם
      </button>
    </div>
  )
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const session = await getServerSession(context.req, context.res, authOptions)

  // If the user is already logged in, redirect.
  // Note: Make sure not to redirect to the same page
  // To avoid an infinite loop!
  const user = await getUser(context)
  if (user) {
    const home = user.isShikum
      ? "/shikum"
      : user.isOrders
      ? "/orders"
      : user.isSubsidy
      ? "/subsidy/orders"
      : null
    if (home) {
      return typeof window !== "undefined"
        ? Router.push(home)
        : context.res.writeHead(302, { Location: home }).end()
    }
  }

  if (session) {
    return { redirect: { destination: "/" } }
  }

  return {
    props: {}
  }
}
